//====================================================
//  Function: Shop hover
//====================================================
function componentShopHover() {
  // if ($('.shop__item--row').length) {
  //   var heroSection = $('.section-hero')
  //   $('.shop__item--row').hover(
  //     function () {
  //       heroSection.addClass(classIsActive)
  //     },
  //     function () {
  //       heroSection.removeClass(classIsActive)
  //     },
  //   )
  // }
}
