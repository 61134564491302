//====================================================
//  Function: Remove block
//====================================================
function componentRemoveBasketItem() {
  $(document).on('click', '.js-remove-trigger-basket', function (e) {
    $(this).closest('.js-remove-block').remove()
    recalculateBasketItem()
    e.preventDefault()
  })
}

function recalculateBasketItem() {
  var headerBasket = $('.js-header-basket')
  var headerBasketItems = headerBasket.find('.js-header-basket-item')
  if (headerBasketItems.length > 0) {
    $('.js-header-basket').removeClass('is-empty')
  } else {
    $('.js-header-basket').addClass('is-empty')
  }
}
