//====================================================
//  Function: Parallax
//====================================================
function componentParallax() {
  if ($('.js-parallax').length) {
    var parallaxImages = document.querySelectorAll('.js-parallax')

    new simpleParallax(parallaxImages, {
      delay: 0.8,
      scale: 1.1,
    })
  }

  if ($('.js-parallax-hero-image').length) {
    gsap.to('.js-parallax-hero-image', {
      yPercent: 5,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-parallax-hero',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
  }

  if ($('.js-parallax-hero-text').length) {
    gsap.to('.js-parallax-hero-text', {
      yPercent: -5,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-parallax-hero',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
  }

  if ($('.js-parallax-icons').length) {
    gsap.to('.js-parallax-icons', {
      yPercent: 12,
      ease: 'none',
      scrollTrigger: {
        trigger: '.js-parallax-icons-section',
        // start: "top bottom", // the default values
        // end: "bottom top",
        scrub: true,
      },
    })
  }
}
