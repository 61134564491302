//====================================================
//  Function: Revealer
//====================================================
function componentRevealer(title) {
  if ($('.js-hero-title').length) {
    var revHeroTitle = new RevealFx(
      document.querySelector('.js-hero-title[data-id="' + title + '"]'),
    )

    revHeroTitle.reveal({
      bgcolor: '#f7f7f7',
      easing: 'easeOutExpo',
      direction: 'bt',
      onStart: function (contentEl, revealerEl) {
        anime.remove(contentEl)
        contentEl.style.opacity = 0
        anime({
          targets: contentEl,
          duration: 800,
          delay: 250,
          easing: 'easeOutExpo',
          translateY: [40, 0],
          opacity: [0, 1],
        })
      },
      onCover: function (contentEl, revealerEl) {},
    })
  }
}
