//====================================================
//  Function: Select
//====================================================
function componentSelect() {
  if ($('.js-select').length) {
    $('.js-select')
      .select2({
        minimumResultsForSearch: Infinity,
        placeholder: function () {
          $(this).data('placeholder')
        },
      })
      .focus(function () {
        $(this).select2('open')
      })

    selectEvents($('.js-select'))
  }
}

function selectEvents(element) {
  element.on('select2:open', function (e) {
    $(this).closest('.js-placeholder-block').addClass(classIsActive)
  })

  element.on('select2:select', function (e) {
    $(this).closest('.js-placeholder-block').addClass(classIsActive)
    $(this).trigger('input')
  })

  element.on('select2:close', function (e) {
    if (!$(this).val().length) {
      $(this).closest('.js-placeholder-block').removeClass(classIsActive)
    }
  })
}
