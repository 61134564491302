//====================================================
//  Function: Scroll to
//====================================================
function partScrollTo() {
  if ($('.js-scroll-trigger').length) {
    $(document).on('click', '.js-scroll-trigger', function () {
      var inst = $(this)
      var target = $(this).attr('data-scroll')
      if ($('#' + target + '').length == 0) {
        return false
      }
      if (target.length) {
        $('html, body')
          .stop()
          .animate({
            scrollTop: $('#' + target + '').offset().top - 65,
          })
        return false
      }
    })
  }
}
