//====================================================
//  Function: Slider
//====================================================
function componentSwiper() {
  productsSlider()
  shopsSlider()
  heroSlider()
}

function heroSlider() {
  if ($('.js-slider-hero').length) {
    var inst = $('.js-slider-hero'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperPagination = swiperBlock.find('.js-swiper-pagination'),
      swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
      swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 0,
      lazy: true,
      loop: true,
      speed: 800,
      autoplay: {
        delay: 4000,
        disableOnInteraction: true,
      },
      pagination: {
        el: swiperPagination,
        clickable: true,
      },
      navigation: {
        nextEl: swiperArrowNext,
        prevEl: swiperArrowPrev,
      },
    })

    mySwiper.on('slideChange', function () {
      var slideIndex = mySwiper.realIndex
      switch (slideIndex) {
        case 0:
          componentRevealer('hero-0')
          break
        case 1:
          componentRevealer('hero-1')
          break
        case 2:
          componentRevealer('hero-2')
          break
      }
      $(
        '.js-tabs-content .js-tab-content-item.' + classIsActive + '',
      ).removeClass(classIsActive)
      $(
        '.js-tabs-content .js-tab-content-item[data-id="hero-' +
          slideIndex +
          '"]',
      ).addClass(classIsActive)
    })
  }
}

function productsSlider() {
  if ($('.js-slider-products').length) {
    var inst = $('.js-slider-products'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperPagination = swiperBlock.find('.js-swiper-pagination')
    // swiperArrowPrev = swiperBlock.find('.js-swiper-arrow-prev'),
    // swiperArrowNext = swiperBlock.find('.js-swiper-arrow-next')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      spaceBetween: 10,
      centeredSlides: true,
      lazy: true,
      loop: true,
      speed: 600,
      slidesPerView: 'auto',
      pagination: {
        el: swiperPagination,
        clickable: true,
      },
    })
  }
}

function shopsSlider() {
  if ($('.js-slider-shops').length) {
    var inst = $('.js-slider-shops'),
      swiperBlock = inst.closest('.js-slider-group'),
      swiperPagination = swiperBlock.find('.js-swiper-pagination')

    var mySwiper = new Swiper(inst, {
      slidesPerView: 1,
      lazy: true,
      loop: true,
      speed: 600,
      pagination: {
        el: swiperPagination,
        clickable: true,
      },
    })
  }
}
